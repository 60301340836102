<template lang='pug'>
	.d-flex.filters
		.filters__wrap(v-if="step == 1 || !isMobile")
			.d-flex.flex-column.pr-md-4.filters__tabs
				span.b3.btn.btn-text.filters__reset-all(v-if="isMobile" @click="resetAll" :class="{'disabled': !used}") {{ $t('button.resetAll') }}

				pw-btn.filters__tab(
					v-for="(tab, i) in audienceFiltersTabs"
					:key="i"
					:class="{'active-tab': tabFilters == i && !isMobile}"
					:text="tab.title"
					:icon="tab.icon"
					:quantity="tab.quantity"
					:variant="'followers-filters'"
					@click.native="tabFilters = i, step = 2, disabledAllTab"
				)

				.d-flex.position-relative.date-picker-control(:class="{'is-float': creationDate.start}")
					v-date-picker.w-100.date-picker(v-model="creationDate" :key="creationDateKey" :min-date="new Date(project.createdAt)" :max-date="new Date()" :masks="masks" is-range :locale="currentLanguage" :popover="{ visibility: 'click' }")
						template(v-slot="{ inputValue, inputEvents, togglePopover }")
							span.icon-calendar(@click="togglePopover()")
							.d-flex.date-picker__input(@click="togglePopover()")
								input.b3.mr-1.date-picker__input-calendar(:class="{'visually-hidden': !creationDate.start && !creationDate.end}" id="creation-date-calendar" :value="inputValue.start" v-on="inputEvents.start" readonly)
								span.b3.mr-1(v-if="creationDate.start && creationDate.end") -
								span.b3.date-picker__input-calendar(v-if="creationDate.start && creationDate.end") {{ inputValue.end }}
								input.visually-hidden(:value="inputValue.end" v-on="inputEvents.end")
					label.m-0.b3.date-picker__label(:class="{'b4': creationDate.start && creationDate.end}" for="creation-date-calendar") {{ $t('project.addShort') }}
					icon-close.date-picker__reset(v-if="creationDate.start" @click="creationDate.start = null, creationDate.end = null, creationDateKey++")

				.d-flex.position-relative.date-picker-control(:class="{'is-float': completionDate.start}")
					v-date-picker.w-100.date-picker(v-model="completionDate" :key="completionDateKey" :min-date="new Date(project.createdAt)" :max-date="new Date()" :masks="masks" is-range :locale="currentLanguage" :popover="{ visibility: 'click' }")
						template(v-slot="{ inputValue, inputEvents, togglePopover}")
							span.icon-calendar(@click="togglePopover()")
							.d-flex.date-picker__input(@click="togglePopover()")
								input.b3.mr-1.date-picker__input-calendar(:class="{'visually-hidden': !completionDate.start && !completionDate.end}" id="completion-date-calendar" :value="inputValue.start" v-on="inputEvents.start" readonly)
								span.b3.mr-1(v-if="completionDate.start && completionDate.end") -
								span.b3.date-picker__input-calendar(v-if="completionDate.start && completionDate.end") {{ inputValue.end }}
								input.visually-hidden(:value="inputValue.end" v-on="inputEvents.end")
					label.m-0.b3.date-picker__label(:class="{'b4': completionDate.start && completionDate.end}" for="completion-date-calendar") {{ $t('project.completion') }}
					icon-close.date-picker__reset(v-if="completionDate.start" @click="completionDate.start = null, completionDate.end = null, completionDateKey++")

			.d-flex.justify-content-between.filters__buttons
				.btn.btn-primary.b2.filters__btn(@click="applyFilter(), isloading = true")
					.loader(v-if="isloading || isPending && !isloadingReset")
					span(v-else='') {{ $t('paymentPage.apply') }}
				.btn.btn-border.text-nowrap.b2.filters__btn(v-if="!isMobile" @click="resetAll" :class="{'disabled': !used}")
					.loader.primary(v-if="isloadingReset")
					span(v-else='') {{ $t('button.resetAll') }}

		.d-flex.flex-column.w-100.filters__tabs(v-if="step == 2 || !isMobile")
			.d-flex.justify-content-between.align-items-center.filters__header
				svg.cursor-pointer.mr-3(v-if="isMobile" @click="step = 1" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg")
					path(d="M15 19.9181L8.47997 13.3981C7.70997 12.6281 7.70997 11.3681 8.47997 10.5981L15 4.07812" stroke="#8292A1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
				h3.h3.text-nowrap(v-if="isMobile") {{ audienceFiltersTabs[tabFilters].title }}
				.d-flex.flex-column.w-100
					.d-flex.justify-content-end.justify-content-md-between.align-items-center(v-if="tabFilters == 0 || tabFilters == 1 || tabFilters == 2")
						span.b3.filters__choose-all(v-if="tabFilters == 0" :class="{'active-choose-all': statusBotCross}" @click="chooseAllTab") {{ $t('button.chooseAll') }}
							i.fontello.fontello-icon-cross-bold(v-if="statusBotCross")
						icon-close.filters__icon-close(v-if="!isMobile && tabFilters == 0" @click="$emit('click', $event)")

						span.b3.filters__choose-all(v-if="tabFilters == 1" :class="{'active-choose-all': statusCross}" @click="chooseAllTab") {{ $t('button.chooseAll') }}
							i.fontello.fontello-icon-cross-bold(v-if="statusCross")
						icon-close.filters__icon-close(v-if="!isMobile && tabFilters == 1" @click="$emit('click', $event)")

						span.b3.filters__choose-all(v-if="tabFilters == 2" :class="{'active-choose-all': plansCross}" @click="chooseAllTab") {{ $t('button.chooseAll') }}
							i.fontello.fontello-icon-cross-bold(v-if="plansCross")
						icon-close.filters__icon-close(v-if="!isMobile && tabFilters == 2" @click="$emit('click', $event)")

			b-form-checkbox.b3.position-relative.filters__tab-checkbox.filters__tab-checkbox--status(
				v-if="tabFilters == 0"
				v-for="(statusBot, i) in $t('statusesBot')"
				:key="i"
				:class="{'active': i == 'active', 'completed': i == 'inactive', 'withoutSubscription': i == 'notActivated'}"
				v-model="statusBotValue"
				:value="i"
				@change="disabledAllTab"
			)
				span.position-absolute
				| {{ statusBot }}

			b-form-checkbox.b3.position-relative.filters__tab-checkbox.filters__tab-checkbox--status(
				v-if="tabFilters == 1 && i != 'unknown'"
				v-for="(status, i) in $t('statuses')"
				:key="i"
				:class="{'active': i == 'active', 'trial': i == 'trial', 'completed': i == 'closed', 'unidentified': i == 'unknown', 'withoutSubscription': i == 'withoutSubscription'}"
				v-model="statusValue"
				:value="i"
				@change="disabledAllTab"
			)
				span.position-absolute
				| {{ status }}

			b-form-checkbox.b3.position-relative.filters__tab-checkbox(
				v-if="tabFilters == 2"
				v-for="(planBot, i) in $t('plansBot')"
				:key="i"
				v-model="plansValue"
				:value="i"
				@change="disabledAllTab"
			)
				span.position-absolute
				| {{ planBot }}

			.mt-auto
				.btn.btn-primary.w-100.mt-3.mt-md-0.filters__btn(v-if="isMobile" @click="step = 1")
					.loader(v-if="isloading || isPending")
					span(v-else='') {{ $t('paymentPage.apply') }}
</template>

<script>
import PwBtn from "@/components/Buttons/PwBtn";
import { mapActions, mapGetters } from 'vuex';
import IconClose from "@/components/Common/IconClose";
import { STORAGE } from "@/api/storage";
import MyInputSearch from "@/components/UI/MyInputSearch";

export default {
	name: "AudienceFilters",
	components: {
		PwBtn,
		IconClose,
		MyInputSearch
	},
	data: () => ({
		isloading: false,
		isloadingReset: false,
		tabFilters: 0,
		quantity: null,
		disabledResetTab: true,
		step: 1,
		currentLanguage: STORAGE.getLang(),
		masks: {
			input: 'DD.MM.YYYY',
			weekdays: 'WW'
		},
		statusBotValue: [],
		statusValue: [],
		plansValue: [],
		creationDateKey: 0,
		completionDateKey: 0,
		creationDate: {
			start: null,
			end: null,
		},
		completionDate: {
			start: null,
			end: null,
		},
		statusBotCross: false,
		statusCross: false,
		plansCross: false
	}),
	created() {
		this.isloading = false;
		this.setValues();
	},
	computed: {
		...mapGetters({
			project: 'project/opened',
			previousData: 'modals/audienceFilters',
			isPending: 'finance/isPendingAudience'
		}),
		audienceFiltersTabs() {
			return [
				{title: this.$t('project.statusBot'), icon: "fontello-icon-status", quantity: this.statusBotValue.length},
				{title: this.$t('project.statusSub.new'), icon: "fontello-icon-subscriptions", quantity: this.statusValue.length},
				{title: this.$t('promotions.tabs.plans'), icon: "fontello-icon-plans", quantity: this.plansValue.length}
			]
		},
		used() {
			return !(this.statusBotValue.length == 0 && this.statusValue.length == 0 && this.plansValue.length == 0 && this.creationDate.start == null && this.completionDate.start == null)
		}
	},
	mounted() {
		if (this.previousData) {
			if (this.previousData.creationDateStore || this.previousData.completionDateStore)
				this.creationDate = structuredClone(this.previousData.creationDateStore);
				this.completionDate = structuredClone(this.previousData.completionDateStore);
		}
	},
	methods: {
		...mapActions({
		}),
		async applyFilter() {
			this.$emit('onApply', {
				statusBot: this.statusBotValue,
				status: this.statusValue,
				plans: this.plansValue,
				creationDate: this.creationDate,
				completionDate: this.completionDate,
				used: this.used
			});
			this.$store.commit('modals/audienceFilters', {
				statusBotValue: this.statusBotValue,
				statusValue: this.statusValue,
				plansValue: this.plansValue,
				creationDateStore: structuredClone(this.creationDate),
				completionDateStore: structuredClone(this.completionDate),
			})
		},
		resetAll() {
			this.isloadingReset = true;
			this.statusBotValue = [];
			this.statusValue = [];
			this.plansValue = [];
			this.creationDate = {
				start: null,
				end: null,
			},
			this.completionDate = {
				start: null,
				end: null,
			},
			this.creationDateKey++,
			this.completionDateKey++,
			this.disabledAllTab();
			this.statusBotCross = false;
			this.statusCross = false;
			this.plansCross = false;
			this.applyFilter();
		},
		resetAllTab() {
			if(this.tabFilters == 0) {
				this.statusBotValue = [];
				this.statusBotCross = false;
			} else if(this.tabFilters == 1) {
				this.statusValue = [];
				this.statusCross = false;
			} else if(this.tabFilters == 2) {
				this.plansValue = [];
				this.plansCross = false;
			}
			this.disabledAllTab();
		},
		chooseAllTab() {
			if(this.tabFilters == 0) {
				if(this.statusBotValue.length != Object.keys(this.$t('statusesBot')).length) {
					this.statusBotValue = Object.keys(this.$t('statusesBot'));
					this.statusBotCross = true;
				} else {
					this.statusBotValue = [];
					this.statusBotCross = false;
				}
			} else if(this.tabFilters == 1) {
				if(this.statusValue.length != Object.keys(this.$t('statuses')).length) {
					this.statusValue = Object.keys(this.$t('statuses'));
					this.statusCross = true;
				} else {
					this.statusValue = [];
					this.statusCross = false;
				}
			} else if(this.tabFilters == 2) {
				if(this.plansValue.length != Object.keys(this.$t('plansBot')).length) {
					this.plansValue = Object.keys(this.$t('plansBot'));
					this.plansCrossCross = true;
				} else {
					this.plansValue = [];
					this.plansCrossCross = false;
				}
			}
			this.disabledAllTab();
			this.applyFilter();
		},
		disabledAllTab() {
			if(this.tabFilters == 0 && this.statusBotValue == 0) return this.disabledResetTab = true;
			if(this.tabFilters == 1 && this.statusValue == 0) return this.disabledResetTab = true;
			if(this.tabFilters == 2 && this.plansValue.length == 0) return this.disabledResetTab = true;

			this.disabledResetTab = false;

			if(this.tabFilters == 0) {
				if(this.statusBotValue.length == Object.keys(this.$t('statusesBot')).length) {
					this.statusBotCross = true;
				} else {
					this.statusBotCross = false;
				}
			} else if(this.tabFilters == 1) {
				if(this.statusValue.length == Object.keys(this.$t('statuses')).length) {
					this.statusCross = true;
				} else {
					this.statusCross = false;
				}
			} else if(this.tabFilters == 2) {
				if(this.plansValue.length == Object.keys(this.$t('plansBot')).length) {
					this.plansCross = true;
				} else {
					this.plansCross = false;
				}
			}
		},
		setValues() {
			_.each(this.previousData, (v, i) => {
				this[i] = v;
			})
		}
	}
}
</script>
